<template>
  <div class="d-flex flex-column pt-8">
    <div v-for="({ title, phone, icon }, i) in buttons" :key="i" class="button">
      <a
        target="_blank"
        class="button-item"
        :href="getWhatsappLinkFromPhone(phone)"
      >
        <v-icon
          class="button-item-icon"
          :size="xs ? 25 : sm ? 30 : 50"
          v-if="icon"
          :icon="icon"
        ></v-icon>
        <div class="text-caption text-sm-body-1 px-10 text-center">
          <span class="font-weight-bold primary-font">
            {{ title }}
          </span>
        </div>
        <v-btn
          :href="`https://api.whatsapp.com/send/?text=${getWhatsappLinkFromPhone(
            phone
          )}`"
          height="36px"
          width="36px"
          icon
          flat
          class="button-item-icon-share"
        >
          <v-icon size="25" icon="mdi-share"></v-icon>
        </v-btn>
      </a>
    </div>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";
const { xs, sm } = useDisplay();

const buttons = [
  // {
  //   icon: "mdi-note-text-outline",
  //   title: "Baixe nosso Catálogo de Natal",
  //   phone: "https://drive.google.com/file/d/1iE3hGuXhQINhd5SUgU3MvhVVQjyfX8ck/view",
  // },

  {
    icon: "mdi mdi-store",
    title: "Loja Kazza Aconchego",
    phone: "66 9995-0027",
  },
  {
    title: "Consultora Graciele",
    phone: "66 9690-0090",
  },
  {
    title: "Consultora Gleice",
    phone: "66 9662-0090",
  },
  // {
  //   title: "Consultora Leila",
  //   phone: "66 9639-2144",
  // },
  {
    title: "Consultora Dalva",
    phone: "66 9639-2144",
  },
  // {
  //   title: "Deyse Financeiro",
  //   phone: "http://encurtador.com.br/glJ69",
  // },
  // {
  //   title: "Consultora Ana Karolina",
  //   phone: "http://encurtador.com.br/juyE4",
  // },
];

const getWhatsappLinkFromPhone = (phone) => {
  return "https://wa.me/55" + phone.replace(/[^0-9]/g, "");
};
</script>

<style lang="scss" scoped>
.button {
  z-index: 0;
  overflow: hidden;
  margin-bottom: 16px;
  background-color: rgb(255, 248, 224);
  color: rgb(0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;
  border-radius: 28px;
  height: 56px;
  transition: all 0.15s;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }

  &:hover .button-item-icon-share {
    opacity: 1;
    pointer-events: all;
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
    width: 100%;
    height: 100%;
    z-index: 1;
    &-icon {
      position: absolute;
      left: 10px;
      &-share {
        position: absolute;
        right: 10px;
        opacity: 0;
        pointer-events: none;
        z-index: 9999;
      }
    }
  }
}
</style>
