<template>
  <div class="top-section d-flex justify-center flex-column">
    <div class="d-flex justify-center align-center fixed-bar hidden px-3">
      <v-sheet
        class="fixed-bar-content pa-4 d-flex justify-space-between align-center"
        max-width="800px"
        width="100%"
        height="100%"
      >
        <img src="/img/logo.jpg" />
        <span class="font-weight-bold primary-font"> @kazzaaconchego </span>
        <v-btn
          :href="`https://api.whatsapp.com/send/?text=${currentURL}`"
          height="36px"
          width="36px"
          icon
          flat
          class="button-item-icon-share"
        >
          <v-icon size="25" icon="mdi-share"></v-icon>
        </v-btn>
      </v-sheet>
    </div>
    <div class="d-flex justify-center pb-4">
      <img src="/img/logo.jpg" />
    </div>
    <div class="text-h6 text-center">
      <span class="font-weight-bold primary-font"> @kazzaaconchego </span>
    </div>
    <div class="text-center">
      <span class="primary-font top-section-sub font-weight-bold"
        >▫️Amor em cada detalhe! ▫️ Cama | Mesa | Banho ✨</span
      >
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    currentURL: "",
  }),

  methods: {
    getURL() {
      this.currentURL = window.location;
    },
    scroll() {
      const toogleBar = this.getToolBar();
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 64) {
          toogleBar.classList.remove("hidden");
        } else {
          toogleBar.classList.add("hidden");
        }
      });
    },
    getToolBar() {
      return document.querySelector(".fixed-bar");
    },
  },
  mounted() {
    this.getURL();
    this.scroll();
  },
};
</script>

<style lang="scss" scoped>
.top-section {
  .hidden {
    opacity: 0;
    pointer-events: none;
  }
  & img {
    border-radius: 50%;
    height: 96px;
    aspect-ratio: 1 / 1;
    object-fit: contain;
  }

  &-sub {
    color: #00000099;
    font-size: 16px;
    line-height: 1.5;
  }
  & .fixed-bar {
    z-index: 10;
    position: fixed;
    top: 12px;
    left: 0;
    width: 100%;
    height: 70px;
    &-content {
      border: 1px solid rgb(235, 238, 241);
      border-radius: 72px;
      background-color: rgba(255, 255, 255, 0.5);
      transition: background-color 150ms ease 0s,
        -webkit-transform 150ms ease 0s, -webkit-backdrop-filter 150ms ease 0s;
      backdrop-filter: blur(10px);
      & img {
        height: 44px;
        aspect-ratio: 1 / 1;
        object-fit: contain;
      }
    }
  }
}
</style>
